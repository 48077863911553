import {createSlice} from "@reduxjs/toolkit"


const initialStateValue = {
  "id": "",
  "title": ["SM", "Media"],
  "companyName": "Scott McNamara Media",
  "url": "",
  "image": "",
  "image2": "",
  "shortDescription": "",
  "workDates": "",
  "roles": [],
  "colors": ["#0E0E11", "#ffffff"],
  "preview": false
}

export const projectSlice = createSlice({
  name: "project",
  initialState: { value: initialStateValue},
  reducers: {
    setProject: (state, action) => {
      state.value = action.payload
    },

    unsetProject: (state) => {
      state.value = initialStateValue
    }
  }
})


export const { setProject, unsetProject } = projectSlice.actions
export default projectSlice.reducer
