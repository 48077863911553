import React from 'react'
import { useNavigate } from "react-router-dom";

import Footer from '../../components/Footer'

const About = () => {



  return (
    <>
    <div>About Page</div>
    <Footer />
    </>

  )
}

export default About
