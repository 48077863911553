import  React from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion"
import {useNavigate} from "react-router-dom"




const transition1 = {duration: 1.2, ease: [0.6, 0.01, -0.05, 0.9], delay: 0.5};
const transition2 = {duration: 2, ease: [0.43, 0.13, 0.23, 0.96], delay: 0.5};



const ProjectShow = ({isPreview, setPreview, title, image1, image2, color1, color2, link, roles, date, scrollWindow}) => {

  let navigate = useNavigate();



  const checkWheel = (e) =>{

     if (e.nativeEvent.wheelDeltaY > 50) {
      scrollWindow()
     }else if (e.nativeEvent.wheelDeltaY < -50){

       showPortfolio()
    }
  }


  const showPortfolio = () => {

    navigate(`portfolio/${link}`);
    window.scrollTo({ top: `0` });
    document.querySelector('.logoBlock').parentNode.parentNode.style.zIndex = "100"
    document.querySelector('.logoBlock').parentNode.style.pointerEvents = "none"

    setTimeout(() => {
      document.querySelector('.logoBlock').parentNode.style.pointerEvents = "auto"

    }, 3000);



    setPreview(false)


  }



  const createHeaders = (title) => {

    return(
      <>
        <div className = "headerWrapper "><motion.h1 exit = {{marginLeft: 0, letterSpacing: 0}} transition = {transition1}>{title[0]}</motion.h1></div>
        <div className = "headerWrapper "><motion.h1 exit = {{marginLeft: 0, letterSpacing: 0}} transition = {transition1}>{title[1]}</motion.h1></div>
      </>
    )
  }



  return (
    <Hero  className = "project-hero"
      toggle = {isPreview}
      color1 = {color1}
      color2 = {color2}




    >

    <Wrapper className = "project-hero--wrapper"
      onWheel={checkWheel}
      >
      <div className = 'backTxt project-header'>
        {createHeaders(title)}
      </div>

      <motion.div
        className = "image1"
        exit = {{y: "-100vh"}}
        transition = {transition2}
      >
        <img src ={image1} />
      </motion.div>



      <div className = 'frontTxt project-header'>
        {createHeaders(title)}
      </div>



      <div className = 'mobile-data'>
        <div>
        <p>{date}</p>
        </div>

        <div>
          {
            roles.map((role, i) =>(

              <p key = {i}>{role}</p>

            ))
          }
        </div>

      </div>




      </Wrapper>



    </Hero>
  )
}


export default ProjectShow


const Hero = styled.div`
  position: fixed;


  display: ${props => props.toggle === true ? "flex !important" : "none"};


  *{
    color: ${(props) => props.color2};
  }


`


const Wrapper = styled.div`

  pointer-events: auto;
  .frontTxt h1 {
    -webkit-text-stroke-color: ${(props) => props.color2};
    -webkit-text-fill-color: transparent;
      z-index: 2;


  }

  .backTxt h1 {
      -webkit-text-stroke-color: ${(props) => props.color2};
      -webkit-text-fill-color: ${(props) => props.color2};
      z-index: -1;



  }







`
