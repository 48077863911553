import React from 'react'

const DesignSection = ({fonts, colors}) => {
  return (
    <div   className = "section section--design-system">
    <h2>Design</h2>
    <div className = "fonts">
      <p>Fonts</p>
      <div className = "font-section">
      {fonts.map((font, index) => (
        <div key = {index} className = "font">
          <img src = {font} />
        </div>
      ))}
      </div>


      
    </div>

    <div className = "colors">
      <p>Colours</p>
      <div className = "squares">
      {colors.map((color, index) => (
        <div key = {index} className = "colour">
          <div className = "square" style = {{ backgroundColor: color}}></div>
          <p>{color}</p>
        </div>
      ))}
      </div>


    </div>


    </div>
  )
}

export default DesignSection
