import React from 'react'
import styled from 'styled-components'

import DesignSection from '../../../components/DesignSection'



import image1 from './assets/images/AnnaTran/AnnaTran-1.png'


import phone1 from './assets/images/AnnaTran/AnnaTran-Phone-1.png'
import phone2 from './assets/images/AnnaTran/AnnaTran-Phone-2.png'

import mac1 from './assets/images/AnnaTran/AnnaTran-Mac-1.png'

import siteMap from './assets/images/AnnaTran/AnnaTran-SiteMap.png'
import fontImage from './assets/images/AnnaTran/Font-Poppins.png'

const AnnaTran = () => {
  return (
    <Container className = "portfolio--container" >


      <div className = "section image-section">
        <img src = {phone1} alt = {'phone-mockup'}/>
      </div>


      <DesignSection
        fonts = {[fontImage]}
        colors = {["#CF3057", "#C0A296", "#707070", "#F1EEE5", "#EAEAEA"]}
      />



      <div className = "section image-section">
        <div className = "title-description">
          <p>Main Page</p>
          <p>Desktop, Mobile</p>
        </div>
        <img src = {image1} alt = {`Desktop Screenshot`}/>
      </div>


      <div className = "section image-section">
        <div className = "title-description">
          <p>Site Map</p>
        </div>
        <img src = {siteMap} alt = {'site map of website'}/>
      </div>


      <div className = "section image-section">
        <img src = {mac1} alt = {'mac mockup'}/>
      </div>


      <div className = "section image-section">
        <img src = {phone2} alt = {'phone mockup'}/>
      </div>

    </Container>
  )
}

export default AnnaTran


const Container = styled.div`
  background: ${(props) => props.color};
  height: 100%;






`
