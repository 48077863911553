
import clubcore from '../assets/slides/clubcore.jpg'
import clubcore2 from '../assets/slides/clubcore2.jpg'

import masterspool from '../assets/slides/masterspool.jpg'
import masterspool2 from '../assets/slides/masterspool2.jpg'

import slingersolutions from '../assets/slides/slingersolutions.jpg'
import slingersolutions2 from '../assets/slides/slingersolutions2.jpg'

import westmount from '../assets/slides/westmount.jpg'
import westmount2 from '../assets/slides/westmount2.jpg'

import annatran from '../assets/slides/annatran.jpg'
import annatran2 from '../assets/slides/annatran2.jpg'

import pokemon from '../assets/slides/pokemon.jpg'
import pokemon2 from '../assets/slides/pokemon2.jpg'

import aberdeen from '../assets/slides/aberdeen.jpg'
import aberdeen2 from '../assets/slides/aberdeen2.jpg'





export const data = [
    {
      "id": "club-core",
      "title": ["Club", "Core"],
      "companyName": "Club Core",
      "url": "https://www.clubcore.ca/",
      "image": clubcore,
      "image2": clubcore2,
      "shortDescription": "",
      "workDates": "2018 - Present",
      "roles": [
        "Logo Design",
        "Website Build / Design",
        "Marketing Video Creation"
      ],
      "colors": [
        "#3C2D3C",
        "#00B76D"
      ]
    },

    {
      "id": "slinger-solutions",
      "title": ["Slinger", "Solutions"]
,      "companyName": "Slinger Solutions",
      "url": "https://www.slingersolutions.com/",
      "image": slingersolutions,
      "image2": slingersolutions2,
      "shortDescription": "",
      "workDates": "2020-2021",
      "roles": [
        "Website Build / Design"
      ],
      "colors": [
        "#7A7C82",
        "#F9C06E"

      ]
    },

    {
      "id": "masters-golf-pool",
      "title": ["The Masters", "Golf Pool"],
      "companyName": "The Masters Golf Pool",
      "url": "https://www.scottmcnamaramedia.com/masterspool/",
      "image": masterspool,
      "image2": masterspool2,
      "shortDescription": "",
      "workDates": "2012 - Present",
      "roles": [
        "Website Build / Design",
        "Web / Data Scraping"
      ],
      "colors": [

        "#24534B",
        "#B9B040"
      ]
    },
    {
      "id": "westmount-golf",
      "title": ["Westmount", "Golf & CC"],
      "companyName": "Westmount Golf & CC",
      "url": "https://www.westmountgolf.com/",
      "image": westmount,
      "image2": westmount2,
      "shortDescription": "",
      "workDates": "2018 - Present",
      "roles": [
        "Print Design & Creation"
      ],
      "colors": [
        "#D8EAE6",
        "#BBA46E"
      ]
    },
    {
      "id": "anna-tran-real-estate",
      "title": ["Anna Tran", "Real Estate"],
      "companyName": "Anna Tran Real Estate",
      "url": "https://www.annatran.com/",
      "image": annatran,
      "image2": annatran2,
      "shortDescription": "",
      "workDates": "2022",
      "roles": [
        "Web Build / Design",
        "Print Design & Creation"
      ],
      "colors": [
        "#F1EEE5",
        "#C14B5D"
      ]
    },

    {
      "id": "photoshop-art",
      "title": ["Photoshop", "Art"],
      "companyName": "Photoshop Art",
      "url": "https://www.instagram.com/",
      "image": pokemon,
      "image2": pokemon2,
      "shortDescription": "",
      "workDates": "2022",
      "roles": [
        "Photoshop Art / Photo Manipulation"
      ],
      "colors": [
        "#EBECFF",
        "#62BDFF"
      ]
    }

    // {
    //   "id": "academy-aberdeen",
    //   "title": ["Academy On", "Aberdeen"],
    //   "companyName": "The Golf Academy on Aberdeen",
    //   "url": "https://www.westmountgolf.com/",
    //   "image": aberdeen,
    //   "image2": aberdeen2,
    //   "shortDescription": "",
    //   "workDates": "2021",
    //   "roles": [
    //     "Logo Design"
    //   ],
    //   "colors": [
    //     "#172D4F",
    //     "#C1A60D"
    //   ]
    // }

  ]
