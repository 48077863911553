import React from 'react'
import Navbar from "./components/Navbar"

import useWindowSize from './hooks/useWindowSize'



import Home from "./pages/Home"
import Mobile from './pages/Mobile'
import About from "./pages/About"
import Contact from "./pages/Contact"

import Portfolio from "./pages/Portfolio"

import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import {AnimatePresence} from 'framer-motion'




const AnimatedRoutes = ({isPreview, setPreview}) => {

  const size = useWindowSize();
  const location = useLocation();

  
  return (
    <>
      <Navbar
          isPreview = {isPreview}
          setPreview = {setPreview}
          path = {location}
        />


      <AnimatePresence initial="false" >


        <Routes location={location}  key = {location.pathname} >


          { size.width < 625 ?
            ( <Route exact path="/" element={<Mobile isPreview = {isPreview} setPreview = {setPreview} path = {location}/>}/>
            )  :
            (
              <Route exact path="/" element={<Home isPreview = {isPreview} setPreview = {setPreview} path = {location}/>}/>
            )
          }


            <Route exact path="/" element={<Home isPreview = {isPreview} setPreview = {setPreview} path = {location}/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/contact" element={<Contact/>}/>



            <Route exact path="/portfolio/:id" element={<Portfolio isPreview = {isPreview}/>}>



          </Route>



        </Routes>

      </AnimatePresence>


      </>
  )
}

export default AnimatedRoutes
