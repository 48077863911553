import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/file'

import DesignSection from '../../../components/DesignSection'



// Images
import image1 from './assets/images/Slinger/Slinger-1.png'
import fontImage from './assets/images/Slinger/Font-Avenir-Next.png'
import siteMap from './assets/images/Slinger/Slinger-SiteMap.png'

import phone1 from './assets/images/Slinger/Slinger-Phone-1.png'
import phone2 from './assets/images/Slinger/Slinger-Phone-2.png'

import mac1 from './assets/images/Slinger/Slinger-Mac-1.png'




// Videos
import video1 from './assets/videos/Slinger/Slinger-1.mp4'
import video2 from './assets/videos/Slinger/Slinger-2.mp4'



const SlingerSolutions = () => {
  return (
    <Container className = "portfolio--container" >
      <div className = "section section--description">
        <h2>About the Project</h2>

        <p>
          Slinger Solutions reached out to me to not only redesign their website but also to change the process on how they captured business and lead generation. The main challenge was to come up with a updated modern feel that fit with their current brand identity
        </p>
      </div>

      <div className = "section image-section">
        <img src = {phone1} />
      </div>

      <DesignSection fonts = {[fontImage]} colors = {["#FF9F00", "#060808", "#FFFFFF"]} />


      <div className = "section image-section">
        <div className = "image-section--description">
          <p>Main Page</p>
          <p>Desktop, Mobile</p>
        </div>
        <img src = {image1} />
      </div>



      <div className = "section image-section">
        <div className = "title-description">
          <p>Site Map</p>
        </div>
        <img src = {siteMap} />
      </div>


      <div className = "section image-section">
        <img src = {mac1} />
      </div>

      <div className = "section video-section">
        <ReactPlayer
          playing

          width = "100%"
          height = "100%"
          controls = {true}
          playbackRate  = {0.75}
          url={video1} />
      </div>


      <div className = "section image-section">
        <img src = {phone2} />
      </div>


    </Container>
  )
}

export default SlingerSolutions



const Container = styled.div`
  background: ${(props) => props.color};
  height: 100%;




`
