
import React from 'react'
import styled from 'styled-components'

import './hero.css';

const Hero = ({bgColor, toggleShow}) => {
  return (

    <Container className = "heroSection" open = {toggleShow} bgColor = {bgColor}>
      <div className = "heroContainer">
        <p className = "heroSmall">
          <span>Let's</span>
          <span>Create</span>
          <span>Something</span>
        </p>
        <h1 className="heroBig">Awesome</h1>
      </div>


    </Container>


  )
}

export default Hero






const Container = styled.div`
  background: ${(props) => props.bgColor};


  .heroContainer{
    opacity: ${props => props.open === true ? "0 !important" : "1"};

  }

`
