import React, {useState} from 'react';

import Leftbar from "./components/Leftbar"
import AnimatedRoutes from "./AnimatedRoutes"

import { BrowserRouter as Router } from "react-router-dom";
import patternBg from './patternBg.png'

import './App.css';


function App() {

  const [isPreview, setPreview] = useState(false)

  return (

    <div className="App">

      <Router>
        <div className = "patternBg top"><img src = {patternBg}/></div>

        <Leftbar />

        <AnimatedRoutes
          isPreview = {isPreview}
          setPreview = {setPreview}

          />


        <div className = "patternBg bottom"><img src = {patternBg}/></div>
      </Router>

    </div>

  )


}

export default App;
