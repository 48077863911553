import React from 'react'
import styled from 'styled-components'

import DesignSection from '../../../components/DesignSection'


// Images
import image1 from './assets/images/ClubCore/ClubCore-2.png'

import phone1 from './assets/images/ClubCore/ClubCore-Phone-1.png'
import phone2 from './assets/images/ClubCore/ClubCore-Phone-2.png'

import logoBlack from './assets/images/ClubCore/ClubCore-Logo-Black.png'
import logoWhite from './assets/images/ClubCore/ClubCore-Logo-White.png'

import logo1 from './assets/images/ClubCore/ClubCore-Logo-1.png'
import logo2 from './assets/images/ClubCore/ClubCore-Logo-2.png'
import logo3 from './assets/images/ClubCore/ClubCore-Logo-3.png'
import logo4 from './assets/images/ClubCore/ClubCore-Logo-4.png'
import logo5 from './assets/images/ClubCore/ClubCore-Logo-5.png'
import logo6 from './assets/images/ClubCore/ClubCore-Logo-6.png'
import logo7 from './assets/images/ClubCore/ClubCore-Logo-7.png'

import stationary1 from './assets/images/ClubCore/ClubCore-Stationary-1.png'
import fontImage from './assets/images/ClubCore/Font-Din.png'
import siteMap from './assets/images/ClubCore/ClubCore-SiteMap.png'

const ClubCore = ({color1}) => {


  return (
    <Container className = "portfolio--container" >

      <div className = "section section--description">
        <h2>About the Project</h2>

        <p>
          Club Core, a SaaS company, was created to enhance the golf club experience by creating an all-in-one solution that can be accessed by both members and staff. Many golf course and club software is great for certain aspects of operations management but lacks staff training courses, board collaboration tools and anything to do with member engagement. Club Core was designed to work alongside existing golf course management software.

          <br/>
          <br/>
          Their web-based interface and app is feature-rich and designed to help golf and sports club managers create a better overall experience. They reached out to me to create a brand identity that captured who they were as a company and then implement that identity into a new website design & build along with many marketing assets & content.
        </p>
      </div>


      <DesignSection
        fonts = {[fontImage]}
        colors = {["#333334", "#00AD6B", "#F40680", "#FFA645", "#D8D8D8"]}
      />



      <div className = "section image-section">
        <div className = "title-description">
          <p>Main Page</p>
          <p>Desktop, Mobile</p>
        </div>
        <img src = {image1} />
      </div>


      <div className = "section image-section">
        <img src = {phone1} />
      </div>


      <div className = "section image-section">
        <img src = {phone2} />
      </div>


      <div className = "section image-section">
        <div className = "title-description">
          <p>Site Map</p>
        </div>
        <img src = {siteMap} />
      </div>






      <div className = "section section--description">
        <h2>Logo Design</h2>

        <p>
          Club Core, a SaaS company, was created to enhance the golf club experience by creating an all-in-one solution that can be accessed by both members and staff. Many golf course and club software is great for certain aspects of operations management but lacks staff training courses, board collaboration tools and anything to do with member engagement. Club Core was designed to work alongside existing golf course management software.

          <br/>
          <br/>
          Their web-based interface and app is feature-rich and designed to help golf and sports club managers create a better overall experience. They reached out to me to create a brand identity that captured who they were as a company and then implement that identity into a new website design & build along with many marketing assets & content.
        </p>
      </div>

      <div className = "section logo-section">

        <div className = "logo-col-2">
          <div className = "logo-container">
            <img src = {logoBlack} />
          </div>

          <div className = "logo-container">
            <img src = {logoWhite} />
          </div>
        </div>

      </div>


      <div className = "section logo-section">


        <div className = "logo-col-3">
          <div className = "logo-container">
            <img src = {logo1} />
            <p>Stacked Logo Mark</p>
          </div>
          <div className = "logo-container">
            <img src = {logo2} />
            <p>Horizontal Logo Mark</p>
          </div>
          <div className = "logo-container">
            <img src = {logo3} />
            <p>Icon Logo Mark</p>
          </div>
        </div>

      </div>


      <div className = "section logo-section">


        <div className = "logo-sketch logo-sketch">
          <div className = "logo-container">
            <img src = {logo7} />

          </div>

        </div>

      </div>


      <div className = "section logo-section">


        <div className = "logo-sketch logo-sketch-3">
          <div className = "logo-container">
            <img src = {logo4} />

          </div>
          <div className = "logo-container">
            <img src = {logo5} />

          </div>
          <div className = "logo-container">
            <img src = {logo6} />

          </div>
        </div>

      </div>



      <div className = "section image-section">
        <img src = {stationary1} />
      </div>



    </Container>
  )
}

export default ClubCore


const Container = styled.div`
  background: ${(props) => props.color};
  height: 100%;

`
