import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import {useParams} from "react-router-dom"
import { motion } from "framer-motion"


import './projects/portfolio.css';


import {data} from '../../data/data.js'
import { setProject } from "../../features/projects"


import Footer from '../../components/Footer'

import ClubCore from "./projects/ClubCore"
import SlingerSolutions from "./projects/SlingerSolutions"
import MastersPool from "./projects/MastersPool"
import Pokemon from "./projects/Photoshop"
import AnnaTran from "./projects/AnnaTran"
import Westmount from "./projects/Westmount"
import Aberdeen from "./projects/Aberdeen"



// const transition1 = {duration: 1.2, ease: [0.6, 0.01, -0.05, 0.9], delay: 0.5, };
const transition2 = {duration: 2, ease: [0.43, 0.13, 0.23, 0.96], delay: 0.5, };


const Portfolio = ({isPreview, setPreview}) => {

  const dispatch = useDispatch()
  const project = useSelector((state) => state.project.value)
  document.querySelector('body').style.height = "auto";
  const { id } = useParams();


  const [isLock, setLock] = useState(true)



  useEffect(()=>{
    data && data.filter(info => info.id === id).map(singleProject => {

        dispatch(setProject({
          id: singleProject.id,
          title: singleProject.title,
          companyName: singleProject.companyName,
          url: singleProject.url,
          shortDescription: singleProject.shortDescription,
          workDates: singleProject.workDates,
          roles: singleProject.roles,
          colors: singleProject.colors,
          image1: singleProject.image,
          image2: singleProject.image2,

        }))

    })

  }, [])

  useEffect(()=>{
    setTimeout(() => {
      setLock(false)
    }, "2000")


  }, [])


  const createHeaders = (title) => {

    return(
      <>
        <div className = "headerWrapper "><h1>{title[0]}</h1></div>
        <div className = "headerWrapper "><h1>{title[1]}</h1></div>
      </>
    )
  }



  return (

      <>

      {
        data && data.filter(info => info.id === id).map(singleProject => (
          <Container key = {singleProject.id}
            className = "wrapper"
            color1 = {singleProject.colors[0]}
            color2 = {singleProject.colors[1]}
            locked = {isLock}

            >
          <Hero className = "project-hero"
            color1 = {singleProject.colors[0]}
            color2 = {singleProject.colors[1]}

            >
            <Wrapper className = "project-hero--wrapper"
              color1 = {singleProject.colors[0]}
              color2 = {singleProject.colors[1]}
              >
              <motion.div className = 'backTxt project-header'
                initial= {{opacity: 0}}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.75 }}


                >
                {createHeaders(singleProject.title)}
              </motion.div>


              <div className = "image1">
                <img src ={singleProject.image} />
              </div>




              <motion.div
                className = "image2"

                animate={{ y: "0" }}

                transition = {transition2}
              >
                <img src = {singleProject.image2}/>

              </motion.div>

              <motion.div className = 'frontTxt project-header'


                initial= {{opacity: 0}}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.75 }}>
                {createHeaders(singleProject.title)}
              </motion.div>


              <div className = 'mobile-data'>
                <div>
                <p>{singleProject.workDates}</p>
                </div>

                <div>
                  {
                    singleProject.roles.map((role, i) =>(

                      <p key = {i}>{role}</p>

                    ))
                  }
                </div>

              </div>




              </Wrapper>


              <motion.div className = "rightBar">
                <div className = "box">1</div>
                <div className = "box">2</div>
                <div className = "box">3</div>
                <div className = "box">4</div>

                <div className = "box">1</div>
                <div className = "box">2</div>
                <div className = "box">3</div>
                <div className = "box">4</div>




              </motion.div>


              <Footer color = {project.colors[1]} link = {project.id} setPreview = {setPreview} isPreview = {isPreview}/>


          </Hero>
          {
            id === 'club-core' ? ( <ClubCore id = {id}/> ) :
            id === 'slinger-solutions' ? (<SlingerSolutions id = {id}/>):
            id === 'masters-golf-pool' ? (<MastersPool id = {id}/>):
            id === 'westmount-golf' ? (<Westmount id = {id}/>):
            id === 'anna-tran-real-estate' ? (<AnnaTran  id = {id}/>):
            id === 'photoshop-art' ? (<Pokemon id = {id}/>):
            id === 'academy-aberdeen' ? (<Aberdeen id = {id}/>):
            ("")
          }
        </Container>


        ))

      }



      </>


  )
}

export default Portfolio


const Container = styled.div`
  background: ${(props) => props.color1};


  height: ${props => props.locked === true ? "100vh" : "auto"};
  overflow: ${props => props.locked === true ? "hidden" : "auto"};


  *{
    color: ${(props) => props.color2};
  }
`


const Hero = styled.div`

  position: relative;


  .rightBar{
    width: 10vw;
    max-height: calc(100vh - 125px);
    height: auto;
    // background: rgba(1,1,1,0.1);
    position: fixed;
    right: 3%;
    top: 50%;
    transform: translateY(-50%);

    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    cursor: pointer;
    display: none;


    @media (max-width: 1200px){
      display: none;
    }


    .box{
      background: rgba(1,1,1,0.1);
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;

    }

  }


`


const Wrapper = styled.div`

  overflow: hidden;



  .frontTxt h1 {
    -webkit-text-stroke-color: ${(props) => props.color2};
    -webkit-text-fill-color: ${(props) => props.color2};
      z-index: 10;
      margin: 0px;
      letter-spacing: 0px;
      display: none;
      -webkit-text-stroke-width: 3px;


  }

  .backTxt h1 {
      -webkit-text-stroke-color: ${(props) => props.color2};
      -webkit-text-fill-color: ${(props) => props.color2};
      z-index: 10;
      margin: 0px;
      letter-spacing: 0px;
      -webkit-text-stroke-width: 3px;



  }

  .image1{
      transform: translateY(-100vh);

  }

  .image2{
      transform: translateY(100vh);

  }

`
