import React, {useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import ProjectShow from "../Portfolio/ProjectShow"


import useWindowSize from '../../hooks/useWindowSize'
import {data} from '../../data/data.js'

import { useSelector, useDispatch } from 'react-redux'
import { setProject, unsetProject } from "../../features/projects"

import Footer from '../../components/Footer'
import Hero from '../../components/Hero'


import './home.css';



const Home = ({setPreview, isPreview, path }) => {

  const dispatch = useDispatch()
  const project = useSelector((state) => state.project.value)

  const size = useWindowSize();
  const scrollContainer = useRef();

  const [portfolio, setPortfolio] = useState();

  const [toggleShow, setToggleShow] = useState(false)
  const [scrollPos, setScrollPos] = useState();

  const [toggleFooter, setToggleFooter] = useState(false)


  if(path.pathname.includes("/") && isPreview === false){
    dispatch(unsetProject())

  }


  useEffect(()=>{
    setPortfolio(data)

  }, [])


  useEffect(()=> {
    setTimeout(() => {
      document.body.style.height = `${scrollContainer.current.getBoundingClientRect().height}px`

    }, 10);

  }, [size.height])


  useEffect(()=>{
    requestAnimationFrame(() => skewScrolling())

  },[])



  const skewConfigs = {
    ease: 0.092,
    current: 0,
    previous: 0,
    rounded: 0
  }

  const blockSpecs = {
    width: 35,
    height: 100,
  }


  const practiceToggle = (e) => {
    togglePortfolioPreview(e)
  }



  const togglePortfolioPreview = (e) => {


    if(!toggleShow){
      e.target.classList.toggle('active');


      portfolio.filter(project => project.id === e.target.id).map(filteredProjects => {

          dispatch(setProject({
            id: filteredProjects.id,
            title: filteredProjects.title,
            companyName: filteredProjects.companyName,
            url: filteredProjects.url,
            shortDescription: filteredProjects.shortDescription,
            workDates: filteredProjects.workDates,
            roles: filteredProjects.roles,
            colors: filteredProjects.colors,
            image1: filteredProjects.image,
            image2: filteredProjects.image2,

          }))


      })



      showPortfolio(e.target)
      window.scrollTo({ top: 0, behavior: 'smooth' });

      setPreview(true)
      setScrollPos(window.scrollY);
      setToggleShow(true)
      setToggleFooter(true)



    }else{


      const activeBlock = (scrollContainer.current.querySelector('.active'))

      scrollContainer.current.querySelector('.active').classList.toggle('active')

      showPortfolio(activeBlock)
      window.scrollTo({ top: `${scrollPos}` });
      setToggleShow(false)
      dispatch(unsetProject())
      setPreview(false)
      setToggleFooter(false)



    }





  }



  const showPortfolio = (activeBlock) => {

    let blockSection = [...document.getElementsByClassName("block")]

    const containerHeight = (scrollContainer.current.getBoundingClientRect().height)
    const clickedPos = activeBlock.offsetTop
    const containerMiddle = (containerHeight / 2)
    const moveBlock = clickedPos - containerMiddle - window.scrollY + 50




    if(activeBlock.parentNode.classList.value !== "scroll zoom"){

      document.querySelector('.logoBlock').parentNode.parentNode.style.zIndex = "10"

      activeBlock.style.width = `${blockSpecs.width * 1.3}%`;
      activeBlock.style.height = `${blockSpecs.width * 0.65}vw`;


      blockSection.map((block) => (
        block.style.transform = `translateY(${-1 * moveBlock}px)`
      ))

      activeBlock.parentNode.parentNode.style.display= "flex"


    }


    else{

      activeBlock.style.position = "relative"
      activeBlock.style.opacity = "1"

      document.querySelector('.logoBlock').parentNode.parentNode.style.zIndex = "100"
      activeBlock.style.width = `${blockSpecs.width}%`;
      activeBlock.style.height = `${blockSpecs.height}px`;


      document.body.style.overflow = "auto";
      document.body.style.height = `${activeBlock.parentNode.offsetHeight}px`


      activeBlock.parentNode.style.position = "relative"
      activeBlock.parentNode.parentNode.style.display= "block"

    }

    activeBlock.parentNode.classList.toggle('zoom');

  }




  const skewScrolling = () => {

    if(scrollContainer.current && scrollContainer.current.classList.value !== 'scroll zoom' && path.pathname.includes("/") && isPreview === false){
      skewConfigs.current = window.scrollY;
      skewConfigs.previous += (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease;
      skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100;

      const diff = skewConfigs.current - skewConfigs.rounded;
      const speed = (diff / size.width) * 2;
      const velocity = +speed;
      const skew = velocity * 7.5;
      const width = ((Math.abs(skew)));
      const halfWay = (size.height / 2);




      let elems = [...(scrollContainer.current.children)]

      const opacityMax = Math.max( 1-(window.scrollY*0.02), 0.0);
      document.querySelector('.heroContainer').style.opacity = opacityMax;

      document.querySelector('.footer--socials').style.opacity = window.scrollY*0.001;




      elems.map((item) =>{
        const number = ((window.scrollY - item.offsetTop + halfWay - (item.scrollHeight / 2)))
        const positiveNumber = Math.abs(number)
        const blockWidth = Math.max((1-(positiveNumber / halfWay)), 0.001)*0.09*width

        item.children[0].style.filter = `grayscale(${100 - ( blockWidth * 600 )}%)`
        item.style.transform = `rotateX(${number*skew*0.0008}deg) scaleX(${blockWidth + 1})`


      })

      scrollContainer.current.style.transform = `translateY(-${skewConfigs.rounded}px)`;

    }


    requestAnimationFrame(() => skewScrolling())

  }



  return (


    <>



      <Hero toggleShow = {toggleShow} bgColor = {project.colors[0]}/>


      <Container
        className = "wrapper wrapper--home"
        color1 = {project.colors[0]}
        color2 = {project.colors[1]}
      >
        <HomeBtn className = "homeBtn" onClick = {togglePortfolioPreview} />

        <div ref = {scrollContainer} className = "scroll">

          {
            portfolio &&

            portfolio.map((item)=>(


              <div key = {item.id} id = {item.id} className = "block" onClick = {practiceToggle}>
                <img src = {item.image} alt = {item.id}/>
              </div>

            ))
          }




        </div>

        <ProjectShow
          isPreview = {isPreview}
          setPreview = {setPreview}
          title = {project.title}
          image1 = {project.image1}
          image2 = {project.image2}
          color1 = {project.colors[0]}
          color2 = {project.colors[1]}
          date = {project.workDates}
          roles = {project.roles}
          link = {project.id}
          scrollWindow = {togglePortfolioPreview}

        />



        <Footer color = {project.colors[1]} link = {project.id} setPreview = {setPreview} isPreview = {isPreview}/>
      </Container>





    </>
  )
}

export default Home



const HomeBtn = styled.div`
  background: ${(props) => props.color2};

  svg, path{
    font-size: 12px;
    stroke-width: 3;
    stroke: ${(props) => props.color1};
  }

`




const Container = styled.div`


    .frontTxt h1 {
      -webkit-text-stroke-color: ${(props) => props.color2};
      -webkit-text-fill-color: transparent;

    }

    .backTxt h1 {
        -webkit-text-stroke-color: ${(props) => props.color2};
        -webkit-text-fill-color: ${(props) => props.color2};



    }

`
