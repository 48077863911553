import React from 'react'
import styled from 'styled-components'


import DesignSection from '../../../components/DesignSection'


import fontImage1 from './assets/images/Westmount/Font-Bodoni-Oldstyle.png'
import fontImage2 from './assets/images/Westmount/Font-Montserrat.png'


import cover from './assets/images/Westmount/Westmount-Cover.png'
import book1 from './assets/images/Westmount/Westmount-Book-1.png'
import book2 from './assets/images/Westmount/Westmount-Book-2.png'
import book3 from './assets/images/Westmount/Westmount-Book-3.png'
import book4 from './assets/images/Westmount/Westmount-Book-4.png'
import book5 from './assets/images/Westmount/Westmount-Book-5.png'
import book6 from './assets/images/Westmount/Westmount-Book-6.png'


const Westmount = () => {
  return (
    <Container className = "portfolio--container" >
      <div className = "section section--description">
        <h2>About the Project</h2>

        <p>
          Westmount reached out to me to recreate their wedding and catering packages. The challenge of recreating their packages was to provide the client with a dynamic document that they could easily update images and the menu items, as the menu would be regularly changed.
        </p>
      </div>



      <div className = "section image-section">
        <img src = {book1} />
      </div>


      <DesignSection
        fonts = {[fontImage1, fontImage2]}
        colors = {["#577869", "#D1CBA0", "#FDD5E6", "#D5D5D5", "#FEFEFE"]}
      />




      <div className = "section image-section">
        <img src = {book2} />
      </div>


      <div className = "section image-section">
        <img src = {cover} />
      </div>


      <div className = "section image-section">
        <img src = {book3} />
        <img src = {book6} />
        <img src = {book5} />
        <img src = {book4} />
      </div>



    </Container>
  )
}

export default Westmount



const Container = styled.div`
  background: ${(props) => props.color};
  height: 100%;




`
