import React, {useState, useRef, TouchEvent } from 'react'
import styled from 'styled-components'

import { CgArrowsH } from "react-icons/cg"



const ImageSlider = ({name, number, image1, image2}) => {

  const [imageReveal, setImageReveal] = useState(0.5)
  const imageContainer = useRef(undefined)





  const slide = (xPosition: number): void => {

    const containerBoundingRect = imageContainer.current.getBoundingClientRect()
    setImageReveal(() => {
      if (xPosition < containerBoundingRect.left){
        return 0
      }else if (xPosition > containerBoundingRect.right){
        return 1
      }else{
        return (xPosition - containerBoundingRect.left) / containerBoundingRect.width
      }
    })


  }

  const handleDragger = (): void => {
    window.onmousemove = handleMouseMove
    window.onmouseup = handleMouseUp
  }

  const touchDragger = (event: TouchEvent<HTMLDivElement>): void => {
    slide(event.touches.item(0).clientX)
  }

  const handleMouseMove = (event: MouseEvent): void => {
    slide(event.clientX)



  }

  const handleMouseUp = (): void => {
    window.onmousemove = undefined
    window.onmouseup = undefined
  }





  return (

    <Container revealWidth = {imageReveal} ref = {imageContainer}>

        <img src = {image2} alt="before"/>

      <div className = "image-wrapper">
        <img src = {image1} alt="after"/>
      </div>

      <div className = "handle">
        <div className = "handle-line" >
          <div className = "handle-dragger"
            onMouseDown = {handleDragger}
            onTouchMove = {touchDragger}
            >
            <CgArrowsH />
          </div>
        </div>
      </div>

    </Container>

  )
}

export default ImageSlider


const Container = styled.div`



    width: 35vw;
    max-width: 450px;
    overflow: hidden;
    
    background: white;
    position: relative;
    height: 100%;


    @media (max-width: 800px){
      width: 75vw;
      max-width: none;
    }

    .handle{
      position: absolute;
      top: 0;
      left: 0;
      margin-left: ${props => props.revealWidth * 100}%;
      width: 0px;
      height: 100%;
      z-index: 10;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      user-select: none;


      .handle-line{
        width: 2px;
        background: white;
        flex-grow: 1;

      }

      .handle-dragger{
        width: 22px;
        height: 22px;
        background: white;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 5px 5px rgba(1,1,1,0.1);
        touch-action: none;
        cursor: ew-resize;
        display: flex;
        justify-content: center;
        align-items: center;

        svg, path{
          color: black;

        }


      }


    }

    img{
      display: block;
      width: 35vw;
      max-width: 450px;

      object-fit: cover;
      pointer-events: none;
      user-select: none;
      background: rgb(0,0,0);
      background: linear-gradient(11deg, rgba(164,170,187,1) 0%, rgba(255,255,255,1) 100%);

      @media (max-width: 800px){
        width: 75vw;
        max-width: none;
      }
    }


    .image-wrapper{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: ${props => props.revealWidth * 100}%;

      overflow: hidden;

      img{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

      }



    }




`
