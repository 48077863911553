import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Logo } from '../../logo.svg'
import { GrClose} from "react-icons/gr";

import {FiMail } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";

import { useSelector, useDispatch } from 'react-redux'
import { unsetProject } from "../../features/projects"

import { Link } from "react-router-dom";






const Navbar = ({isPreview, setPreview }) => {




  const dispatch = useDispatch()
  const project = useSelector((state) => state.project.value)



  let backBtnToggle

  if(project.companyName === "Scott McNamara Media"){
    backBtnToggle = false;
  }else{
    backBtnToggle = true;
  }



  const unsetStyles = () => {
    window.scrollTo({ top: 0 });

    dispatch(unsetProject())
    setPreview(false)
  }




  return (
    <Nav
      color1 = {project.colors[0]}
      color2 = {project.colors[1]}
      toggle = {isPreview}
      backBtnToggle = {backBtnToggle}


      >

        <ul>
          <li className = "logoBlock">
            <Link to={`/`} onClick = {unsetStyles}>
              {(project.id === "" || isPreview === false ? (<Logo className = "logo" />) : (<GrClose />))}
            </Link>
          </li>

          <li className = "backBtn" >
            <GrFormClose />
            <Link to={`/`} onClick = {unsetStyles}>Back To Projects</Link>

          </li>


          <li className = "contactBtn" >
            <a href="mailto:sgjmcnamara@gmail.com"><FiMail /></a>
          </li>
        </ul>

    </Nav>
  )
}

export default Navbar


const Nav = styled.div`

  position: fixed;
  inset: 0 0 auto 0;
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 100;





  .contactBtn{

    svg{
      width: 1.85em;
      height: 1.85em;
      stroke-width: 2;
      stroke: ${(props) => props.color2};
    }
  }



  ul{
    display: flex;
    align-items: center;


    width: 100%;
    height: 100%;

    text-transform: uppercase;
    font-size: 12px;
    pointer-events: auto;

    li{
      list-style: none;


      a {
        text-decoration: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.color2};


      }

      &:first-child{
        margin-left: 0;
        margin-right: auto;

        height: 55px;
        width: 55px;
        background: ${(props) => props.color2};
        transition: background 300ms ease-in;

        svg, path{
          width: 65%;
          fill: ${(props) => props.color1};
          stroke: ${(props) => props.color1};
          stroke-width: 4px;

        }

      }

      &:nth-child(2){
        position: absolute;
        left: 50%;
        top: 15px;
        transform: translateX(-50%);
        display: ${props => props.toggle === true || props.backBtnToggle === false ? "none" : "flex"};
        flex-direction: column;
        align-items: center;
        gap: 5px;

        a, svg, path{
          cursor: pointer;
          color: ${props => props.color2};
          stroke: ${props => props.color2};
          font-weight: 400;
          stroke-width: 3;

        }
      }
      &:nth-child(n + 3){

        padding: 0 3% 0 0;
        display: ${props => props.toggle === false ? "flex" : "none"};

        a{
          position: relative;


          &::after {
            content: "";
            position: absolute;

            inset: auto 0 0 0;
            background: ${(props) => props.color2};

            height: 1px;
            margin-bottom: -3px;
            transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.95) 0s;

            transform: scaleX(0);
            transform-origin: left;
            opacity: 0;

          }

          &:hover{
            &::after{
              opacity: 1;
              transform: scaleX(1);
            }
          }
        }




      }
    }
  }

`
