import React from 'react'
import styled from 'styled-components'
import './footer.css';

import {Link} from "react-router-dom";
import { FaInstagram, FaDribbble, FaBehance, FaEtsy, FaLinkedinIn } from "react-icons/fa";
import { GrLinkDown, GrFormAdd } from "react-icons/gr";

import { useSelector } from 'react-redux'

import { motion } from "framer-motion"


const Footer = ({color, link, setPreview, isPreview, company}) => {

  var project = useSelector((state) => state.project.value)

  const transition = {duration: 1, ease: [0.43, 0.13, 0.23, 0.96], delay: 1, };



  const showPortfolio = () => {
    window.scrollTo({ top: `0` });
    document.querySelector('.logoBlock').parentNode.parentNode.style.zIndex = "100"
    document.querySelector('.logoBlock').parentNode.style.pointerEvents = "none"

    setTimeout(() => {
      document.querySelector('.logoBlock').parentNode.style.pointerEvents = "auto"

    }, 3000);

    
    setPreview(false)




  }

  if(company){
    project = company
  }



  let footerToggle


  if(project.companyName === "Scott McNamara Media"){
    footerToggle = false;
  }else{
    footerToggle = true;
  }



  return (
    <Container className = "footer--wrapper" toggle = {footerToggle} >

      <SocialsFooter className = "footer--socials"
        toggle = {footerToggle}
        color1 = {project.colors[0]}
        color2 = {project.colors[1]}>

          <a href = {`https://instagram.com/scottmcnamaramedia/?hl=en`}><FaInstagram/></a>
          <a href = {`https://dribbble.com/scottmcnamaramedia`}><FaDribbble/></a>
          <a href = {`https://www.behance.net/scottmcnamaramedia`}><FaBehance/></a>
          <a href = {`https://www.linkedin.com/in/sgjmcnamara/?originalSubdomain=ca`}><FaLinkedinIn/></a>
          <a href = {`https://www.etsy.com/shop/ScooterTreeCo`}><FaEtsy/></a>
      </SocialsFooter>


      <PortfolioFooter className = "footer--portfolio"
        toggle = {footerToggle}
        color1 = {project.colors[0]}
        color2 = {project.colors[1]}>

          <motion.div className = "footer--roles"
            initial =  {{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit = {{opacity: 0}}
            transition = {transition}
            >
            {
              project.roles.map((role, i) =>(

                <p key = {i}>{role}</p>

              ))
            }
          </motion.div>

          <div className = "footer--link">

            {
              isPreview === true ?
              (

                <Link
                  className = "footer--link-view"
                  to = {`/portfolio/${link}`}
                  onClick ={showPortfolio}
                  >
                  <motion.p
                    initial =  {{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit = {{opacity: 0}}
                    transition = {transition}
                    >
                      View Project

                    <GrLinkDown />
                  </motion.p>

                </Link>

              ) :
              (
                <a
                  className = "footer--link-web"
                  href = {project.url}
                  rel="noreferrer"
                  target = '_blank'
                  onClick ={()=>{ setPreview(false) }}

                  >
                  <motion.p
                    initial =  {{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit = {{opacity: 0}}
                    transition = {transition}

                    >Visit Site

                    <GrFormAdd />
                  </motion.p>

                </a>

              )

            }


          </div>

          <motion.div className = "footer--dates"
            initial =  {{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit = {{opacity: 0}}
            transition = {transition}
            >
            <p>{project.workDates}</p>
          </motion.div>

      </PortfolioFooter>
    </Container>
  )
}

export default Footer



const Container = styled.div`
  position: fixed !important;




`

const SocialsFooter = styled.div`
  display: ${props => props.toggle === false ? "flex" : "none"};


  svg, path{
    fill: ${props => props.color2};
    color: ${props => props.color2};
    stroke: ${props => props.color2};
  }

`

const PortfolioFooter = styled.div`
  display: ${props => props.toggle === true ? "grid" : "none"};


    p{
      color: ${props => props.color2};
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;


    }

  .footer--link{
      p, svg, path{
        cursor: pointer;
        color: ${props => props.color2};
        stroke: ${props => props.color2};
        font-weight: 400;
        stroke-width: 3;

        @media screen and (max-width: 800px){
          color: ${props => props.color1};
          stroke: ${props => props.color1};

        }
      }


  }
  .footer--link-view{
    @media screen and (max-width: 800px){
      background: ${props => props.color2};

    }
  }
  .footer--link-web{
    @media screen and (max-width: 800px){
      background: ${props => props.color2 + "95"};

    }
  }



`
