import React, {useRef, useEffect, useState} from 'react'


import Footer from '../../components/Footer'
import Hero from '../../components/Hero'

import MobileCards from '../../components/MobileCards'

import useWindowSize from '../../hooks/useWindowSize'


import { useSelector, useDispatch } from 'react-redux'
import { setProject, unsetProject } from "../../features/projects"


import './mobile.css';


function Mobile({setPreview, isPreview, path }) {


  const dispatch = useDispatch()
  const [portfolio, setPortfolio] = useState();

  const size = useWindowSize();

  if(path.pathname.includes("/") && isPreview === false){
    dispatch(unsetProject())

  }

  setPreview(false)



  useEffect(()=> {
    setTimeout(() => {
      document.body.style.height = `auto`

    }, 10);

  }, [size.height])



  return (
    <>

      <Hero />

      <MobileCards />

      <Footer />


    </>
  )
}

export default Mobile
