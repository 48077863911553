import React, {useRef, useEffect, useState} from 'react'

import Slider from "react-slick";
import styled from "styled-components"
import {Link} from "react-router-dom";

import { motion } from "framer-motion"




import {data} from '../../data/data.js'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './cards.css';


const transition1 = {duration: 1.2, ease: [0.6, 0.01, -0.05, 0.9], delay: 0.5};
const transition2 = {duration: 2, ease: [0.43, 0.13, 0.23, 0.96], delay: 0.5};

const MobileCardSlider = () => {


  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,

    slidesToScroll: 1
  };

  const [portfolio, setPortfolio] = useState();


  useEffect(()=>{
    setPortfolio(data)

  }, [])


  useEffect(()=>{
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

  }, [])



  const createHeaders = (title) => {

    return(
      <>
        <div className = "headerWrapper "><motion.h1 exit = {{marginLeft: 0, letterSpacing: 0}} transition = {transition1}>{title[0]}</motion.h1></div>
        <div className = "headerWrapper "><motion.h1 exit = {{marginLeft: 0, letterSpacing: 0}} transition = {transition1}>{title[1]}</motion.h1></div>
      </>
    )
  }



  return (
    <Carousel {...settings}>
      <Wrap />
    {
      portfolio &&

      portfolio.map((item)=>(


        <Wrap
          key = {item.id}
          id = {item.id}
          bgColor = {item.colors[0]}
          txtColor = {item.colors[1]}




          >

          <div className = "project-hero--wrapper"

            >
            <div className = 'backTxt project-header'>
              {createHeaders(item.title)}
            </div>

            <motion.div
              className = "image1"
              exit = {{y: "-100vh"}}
              transition = {transition2}
            >
              <img src ={item.image} />
            </motion.div>



            <div className = 'frontTxt project-header'>
              {createHeaders(item.title)}
            </div>



            <div className = 'mobile-data'>
              <div>
              <p>{item.date}</p>
              </div>


            </div>


            <motion.div
              className = "image2"
              initial = {{y: "100vh"}}
              exit = {{y: 0}}
              transition = {transition2}
            >
              <img src ={item.image2} />
            </motion.div>




          </div>


          <Link
            to = {`/portfolio/${item.id}`}

            >
            View Project</Link>







        </Wrap>


      ))
    }
    </Carousel>
  )
}

export default MobileCardSlider


const Carousel = styled(Slider)`

  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;


  margin: 0;

  z-index: 3 !important;



  display: flex !important;
  justify-content: center;
  align-items: center;
  pointer-events: none;




`


const Wrap = styled.div`
  background: ${(props) => props.bgColor};


  z-index: 3 !important;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);


  display: grid !important;

  grid-template-rows: 250px 1fr 65px;
  position: relative;
  width: 100vw;
  padding-top: 55px;



  *{
    pointer-events: auto;

  }



  .image1, .image2{
    width: 100%;

    object-fit: cover;
    object-position: center;
    position: relative;
  }


  .image2{
    position: absolute;

  }




  img{
    margin: auto;

    width: 100% !important;
    object-fit: cover;
    object-position: center;



  }

  .frontTxt h1 {
    -webkit-text-stroke-color: ${(props) => props.txtColor};
    -webkit-text-fill-color: transparent;
      z-index: 2;


  }

  .backTxt h1 {
      -webkit-text-stroke-color: ${(props) => props.txtColor};
      -webkit-text-fill-color: ${(props) => props.txtColor};
      z-index: -1;



  }



  a{
    color: ${(props) => props.bgColor};
    text-decoration: none;
    text-transform: uppercase;
    background: ${(props) => props.txtColor};


    width: 80%;
    height: 50px;

    align-self: center;



    border-radius: 50px;
    font-size: 12px;
    font-weight: 400;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;

    inset: 0 0 0 0;


    margin: auto;
    margin-bottom: 10px;



  }



`
