import React from 'react'
import styled from 'styled-components'

import DesignSection from '../../../components/DesignSection'


import phone1 from './assets/images/Masters/Masters-Phone-1.png'
import phone2 from './assets/images/Masters/Masters-Phone-2.png'

import mac1 from './assets/images/Masters/Masters-Mac-1.png'


import player1 from './assets/images/Masters/Player-1.png'
import player2 from './assets/images/Masters/Player-2.png'
import player3 from './assets/images/Masters/Player-3.png'
import player4 from './assets/images/Masters/Player-4.png'
import player5 from './assets/images/Masters/Player-5.png'
import player6 from './assets/images/Masters/Player-6.png'
import player7 from './assets/images/Masters/Player-7.png'
import player8 from './assets/images/Masters/Player-8.png'


import iPad1 from './assets/images/Masters/Masters-iPad-1.png'
import fontImage from './assets/images/Masters/Font-Helvetica-Neue.png'

const MastersPool = () => {
  return (
    <Container className = "portfolio--container" >
      <div className = "section section--description">
        <h2>About the Project</h2>

        <p>
          Starting off as a fun way to watch golf, The Masters Pool was created for a group of friends to pick a Fantasy Golf Team and compete with one another. Using Microsoft Excel, Excel Sheets were sent at the end of every golf day to update all the participants about results and scoring.
          <br/>
          <br/>

          Always wanting to make a better experience, a plan was made to create a website so that there could be live scoring for my friends to view. Not knowing one single thing about web languages, I began the journey of learning how to create websites and display real time data. From HTML, CSS and PHP to Databases and APIs the Masters Pool has gone through many transformations, each one building on top of previous years. As of present, the Masters Pool is run on React JS with well over 200 participants signing up every year.
        </p>
      </div>



      <div className = "section image-section">
        <img src = {phone1} />
      </div>


      <div className = "section image-section">
        <div className = "player-row">
          <img src = {player1} />
          <img src = {player2} />
          <img src = {player3} />
          <img src = {player4} />
        </div>

        <div className = "player-row">
          <img src = {player5} />
          <img src = {player6} />
          <img src = {player7} />
          <img src = {player8} />
        </div>

      </div>


      <DesignSection
        fonts = {[fontImage]}
        colors = {["#24534B", "#B9B040", "#963835", "#F0F0F2", "#FFFFFF"]}
      />


      <div className = "section image-section">
        <img src = {mac1} />
      </div>


      <div className = "section image-section">
        <img src = {iPad1} />
      </div>


      <div className = "section image-section">
        <img src = {phone2} />
      </div>

    </Container>
  )
}

export default MastersPool


const Container = styled.div`
  background: ${(props) => props.color};
  height: 100%;




`
