import React from 'react'
import styled from 'styled-components'

const Aberdeen = () => {
  return (
    <Container className = "portfolio--container" >
      <div className = "section section--description">
        <h2>About the Project</h2>

        <p>
          Westmount reached out to me to recreate their wedding and catering packages. The challenge of recreating their packages was to provide the client with a dynamic document that they could easily update images and the menu items, as the menu would be regularly changed.
        </p>
      </div>

    </Container>
  )
}

export default Aberdeen



const Container = styled.div`
  background: ${(props) => props.color};
  height: 100%;




`
