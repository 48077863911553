import React from 'react'
import Footer from '../../components/Footer'

const Contact = () => {
  return (
    <>
    <div>Contact Page</div>
    <Footer />
    </>
  )
}

export default Contact
