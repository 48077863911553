import React from 'react'
import styled from 'styled-components'

import CardSlider from '../../../components/CardSlider'

// Images
import image1 from './assets/images/bulbasaur-cartoon.png'
import image2 from './assets/images/bulbasaur-real.jpg'

import image3 from './assets/images/charmander-cartoon.png'
import image4 from './assets/images/charmander-real.jpg'

import image5 from './assets/images/squirtle-cartoon.png'
import image6 from './assets/images/squirtle-real.jpg'

import image7 from './assets/images/beedrill-cartoon.png'
import image8 from './assets/images/beedrill-real.png'

const Pokemon = ({id}) => {

  let pokemonList = [
    {
      "id":"01",
      "name": "Bulbasaur",
      "image1": image1,
      "image2": image2,
    },

    {
      "id":"02",
      "name": "Charmader",
      "image1": image3,
      "image2": image4,
    },

    {
      "id":"03",
      "name": "Squirtle",
      "image1": image5,
      "image2": image6,
    },

    {
      "id":"04",
      "name": "Beedrill",
      "image1": image7,
      "image2": image8,
    }

  ]


  return (
    <Container className = "portfolio--container" >
      <div className = "section section--description">
        <h2>About the Project</h2>

        <p>

        </p>
      </div>

      <div className = "grid-section">

        {
          pokemonList && pokemonList.map((pokemon) => (
            <Card key = {pokemon.id}>
              <div className = "card--title">

              </div>

              <CardSlider image1 = {pokemon.image1} image2 = {pokemon.image2}/>



            </Card>

          ))
        }


      </div>



    </Container>
  )
}

export default Pokemon


const Container = styled.div`



  .grid-section{

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 1000px;
    margin: auto;
    overflow: hidden;



    @media (max-width: 800px){
      grid-template-columns: repeat(1, 1fr);

    }

  }


`

const Card = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;




  .card--title{
    z-index: 2;
    margin-top: 0px;
    width: 100%;
    height: 80px;
    position: absolute;
    z-index: 5;


  }



`
